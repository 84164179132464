module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-21343251-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sapan Diwakar","short_name":"SapanDiwakar","start_url":"/","icon":"src/pages/photo.png","background_color":"#ffffff","theme_color":"#881c17","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"93f92587528fd085e2288b8e202c23ea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"0c51592fa3913dda8c34ecb0bd7011d3","pageViews":"all","trackPageViewsAs":"Loaded a Page"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
